import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledGridItem = styled(Box)(({ theme }) => ({
  padding: '10px 0px 10px 15px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.dark,
  borderRadius: 10,
  '& span': {
    paddingLeft: '19px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
    },
  },
}));

interface BecomeInvestorChipProps {
  image: string;
  children: string;
}

export const BecomeInvestorChip = ({
  image,
  children,
}: BecomeInvestorChipProps) => {
  return (
    <StyledGridItem
      padding="10px 0px 10px 15px"
      mb="29px"
      // flexBasis="30%"
      sx={{
        flexBasis: {
          xs: '48%',
          md: '30%',
        },
      }}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={image} alt={`${children} logo`} />
      <Typography
        component="span"
        color="#111111"
        sx={{
          fontSize: {
            xs: 16,
            md: 20,
          },
        }}
      >
        {children}
      </Typography>
    </StyledGridItem>
  );
};
