import type { NextPage } from 'next';
import Numbers from 'components/landing-page/Numbers';
import Banner from 'components/landing-page/Banner';
import WhyPod from 'components/landing-page/WhyPod';
import HowPodWorks from 'components/landing-page/HowPodWorks';

import FaqLanding from 'components/landing-page/FaqLanding';
import OurPartners from 'components/landing-page/OurPartners';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BecomePodInvestor from 'components/landing-page/BecomePodInvestor';
import { useUser } from 'context/user.context';
// import Head from 'next/head';
import { MetaTags } from 'components/common';
import RaiseFundsWithPod from 'components/landing-page/RaiseFundsWithPod';

const Home: NextPage = () => {
  const [{ user }] = useUser();

  return (
    <>
      <MetaTags
        title={
          user?.role_id === 2
            ? 'Raise funds for your startup'
            : 'Invest in Indian Startups'
        }
        description={
          user?.role_id === 2
            ? 'Raise seed funding for your startup with POD. Register Now!'
            : 'Invest in promising early-stage Indian startups. Become an investor now!'
        }
        imageUrl={`${process.env.NEXT_PUBLIC_FRONTEND_URL}pod-logo.png`}
        url={process.env.NEXT_PUBLIC_FRONTEND_URL!}
        keywords="Invest in startups"
      />
      <Banner userType={user?.role_id} />
      <Numbers />
      <WhyPod userType={user?.role_id || 1} />
      <HowPodWorks userType={user?.role_id || 1} />
      <FaqLanding userType={user?.role_id || 1} />
      <OurPartners />
      {user?.role_id !== 2 ? <BecomePodInvestor /> : <RaiseFundsWithPod />}
    </>
  );
};

export default Home;
