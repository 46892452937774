import { Box, Container, Typography } from '@mui/material';
import { LetterSpacedHeading } from 'components/common';
import HealthCareIcon from 'assets/images/health-care.svg';
import EdTechIcon from 'assets/images/Edtech.svg';
import FintechIcon from 'assets/images/Subtract.svg';
import EsportsIcon from 'assets/images/Esports.svg';
import AgritechIcon from 'assets/images/Agritech.svg';
import TechnologyIcon from 'assets/images/Technology.svg';
import { BecomeInvestorChip } from './BecomeInvestorChip';
import { CustomButton } from 'components/common';
import Link from 'components/Link';

const items: { text: string; image: any }[] = [
  { text: 'Healthtech', image: HealthCareIcon },
  { text: 'Fintech', image: FintechIcon },
  { text: 'Technology', image: TechnologyIcon },
  { text: 'Edtech', image: EdTechIcon },
  { text: 'Agritech', image: AgritechIcon },
  { text: 'Esports', image: EsportsIcon },
];

const BecomePodInvestor = () => {
  return (
    <Box py="50px" sx={{ backgroundColor: 'rgba(218, 255, 243, 0.5)' }}>
      <Container maxWidth="md">
        <LetterSpacedHeading sx={{ pb: '48px' }} component="h2">
          BECOME A POD INVESTOR
        </LetterSpacedHeading>
        <Box
          sx={{
            mx: {
              md: 10,
            },
            textAlign: 'center',
          }}
        >
          <Typography
            color="rgba(17, 17, 17, 0.7)"
            fontSize={20}
            lineHeight="32px"
            fontWeight="normal"
            align="center"
            sx={{
              p: {
                xs: '0 0 30px 0',
                md: '0 25px 40px 25px',
              },
            }}
          >
            We have some of the best curated and vetted startups on our
            platform. Here&apos;s your chance to become a leading investor in
            the next market disruptor.
          </Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            {items.map(({ text, image }, i) => (
              <BecomeInvestorChip key={i} image={image.src}>
                {text}
              </BecomeInvestorChip>
            ))}
          </Box>
          <Link href="/login">
            <CustomButton
              sx={{
                fontSize: 20,
                width: 248,
                height: 62,
                mt: 2,
              }}
              variant="contained"
              disableElevation
            >
              Join Now
            </CustomButton>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default BecomePodInvestor;
